<template>
  <section class='customDetail'>
    <div class="top">
      <div class="back" @click="goBack"><i class="el-icon-arrow-left icon_left"></i>返回</div>
      <div class="company_name">{{state.customForm.customerName}}</div>
    </div>
    <div class="btn_wrap">
      <div class="btn_item" v-for="(item, index) in btnArr" :key="index" :class="{active:index===btnIdx}"
        @click="handleClickItem(index)">{{item}}</div>
    </div>
    <div class="content_wrap">
      <div class="left">
        <div class="base_info_wrap">
          <div class="top_wrap">
            <div class="title">基本信息</div>
            <div class="el-icon-edit-outline" @click="isShow = true"></div>
          </div>
          <div class="info_wrap">
            <div class="info_item">
              <div class="label">客户名称</div>
              <div class="con">{{state.customForm.customerName}}</div>
            </div>
            <div class="info_item">
              <div class="label">客户类型</div>
              <div class="con">{{state.customForm.customerTypeName}}</div>
            </div>
            <div class="info_item">
              <div class="label">客户标签</div>
              <div class="con">{{state.customForm.tagListName}}</div>
            </div>
          </div>
        </div>
        <div class="contract_info_wrap">
          <div class="top_wrap">
            <div class="title">联系人信息</div>
            <div class="el-icon-edit-outline" @click="handleOpenContractDialog"></div>
          </div>
          <div class="contract_list">
            <div class="list_item" v-for="(item, index) in state.contactList" :key="index">
              <div class="avatar">
                <!-- <img src="" alt=""> -->
                <svg class="icon" aria-hidden="true" style="width: 100%;height: 100%;">
                  <use xlink:href="#icontouxiang"></use>
                </svg>
              </div>
              <div class="name">{{item.contacterName}}</div>
              <div class="duty">{{item.position}}</div>
              <div class="tel">{{item.contactNo}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="relevant_case_wrap">
          <div class="top_wrap">
            <div class="title">关联案件</div>
            <div class="el-icon-edit-outline" @click="handleOpenDialog('case')"></div>
          </div>
          <div class="case_list">
            <div class="list_item">
              <div class="case_info">
                <div class="case_top">
                  <div class="case_time">2020-06-21</div>
                  <div class="case_num">苏永（民）2020 第 101 号</div>
                </div>
                <div class="case_bottom">王某某和张三婚姻财产分配纠纷案</div>
              </div>
              <div class="case_status">进行中</div>
            </div>
            <div class="list_item">
              <div class="case_info">
                <div class="case_top">
                  <div class="case_time">2020-06-21</div>
                  <div class="case_num">苏永（民）2020 第 101 号</div>
                </div>
                <div class="case_bottom">王某某和张三婚姻财产分配纠纷案</div>
              </div>
              <div class="case_status">进行中</div>
            </div>
          </div>
        </div>
        <div class="relevant_project_wrap">
          <div class="top_wrap">
            <div class="title">关联项目</div>
            <div class="el-icon-edit-outline" @click="handleOpenDialog('project')"></div>
          </div>
          <div class="case_list">
            <div class="list_item">
              <div class="case_info">
                <div class="case_top">
                  <div class="case_time">2020-06-21</div>
                  <div class="case_num">苏永（民）2020 第 101 号</div>
                </div>
                <div class="case_bottom">王某某和张三婚姻财产分配纠纷案</div>
              </div>
              <div class="case_status">进行中</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 基本信息弹框 -->
    <MyDialog title="填写客户基本信息" :isShow="isShow" :closeDialog="handleCloseDialog">
      <div class="custom_form">
        <el-form :model="state.customForm" :rules="state.rules" ref="customForm" label-width="100px"
          class="demo-ruleForm">
          <div class="form_box">
            <el-form-item label="客户名称" class="form_item_type" prop="customerType">
              <el-select v-model="state.customForm.customerType" size="medium">
                <el-option v-for="item in state.typeArr" :key="item.key" :label="item.value" :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="customerName" class="form_item_name">
              <el-input v-model="state.customForm.customerName"></el-input>
            </el-form-item>
          </div>

          <el-form-item label="客户标签">
            <el-select v-model="state.customForm.tagList" multiple style="width: 100%;" placeholder="请选择" size="medium">
              <el-option v-for="item in state.customerTagArr" :key="item.key" :label="item.value" :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="生日">
            <el-date-picker v-model="state.customForm.birthday" type="date" style="width: 100%" placeholder="年/月/日"
              size="medium" @change="handleChangeTime">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="state.customForm.customerRemark" size="medium"></el-input>
          </el-form-item>
          <div class="btn_wrap">
            <el-button class="btn" type="primary" @click="isShow=false">取消</el-button>
            <el-button class="btn" type="primary" @click="saveBaseInfo">保存</el-button>
          </div>
        </el-form>
      </div>
    </MyDialog>
    <!-- 关联案件/关联项目弹框 -->
    <MyDialog :title="dialogTitle" :isShow="showCaseDialog" :closeDialog="handleCloseDialog">
      <div class="case_dialog">
        <div class="search_wrap" v-show="!isEdit">
          <el-input placeholder="请输入项目名称/项目编号/委托方 进行搜索" prefix-icon="el-icon-search" v-model="state.keyword">
          </el-input>
        </div>
        <div class="case_list">
          <div class="list_item">
            <el-checkbox v-show="!isEdit" v-model="checked"></el-checkbox>
            <div class="case_info">
              <div class="case_top">
                <div class="case_time">2020-06-21</div>
                <div class="case_num">苏永（民）2020 第 101 号</div>
              </div>
              <div class="case_bottom">王某某和张三婚姻财产分配纠纷案</div>
            </div>
            <div class="case_status">进行中</div>
            <div class="remove">
              <el-button type="primary" v-show="isEdit" @click="handleRemoveRelevant">移除</el-button>
            </div>
          </div>
          <div class="list_item">
            <el-checkbox v-show="!isEdit" v-model="checked"></el-checkbox>
            <div class="case_info">
              <div class="case_top">
                <div class="case_time">2020-06-21</div>
                <div class="case_num">苏永（民）2020 第 101 号</div>
              </div>
              <div class="case_bottom">王某某和张三婚姻财产分配纠纷案</div>
            </div>
            <div class="case_status">进行中</div>
            <el-button type="primary" v-show="isEdit">移除</el-button>
          </div>
        </div>
        <div class="btn_wrap">
          <el-button type="primary" @click="handleAddRelevance">{{btnText}}</el-button>
        </div>
        <div class="back el-icon-arrow-left" v-show="!isEdit" @click="handleBack"></div>
      </div>
    </MyDialog>
    <!-- 联系人信息弹框 -->
    <MyDialog title="编辑联系人信息" :isShow="showContractDialog" :closeDialog="handleCloseDialog">
      <div class="contract_dialog">
        <div class="contract_form">
          <el-form ref="contractForm" label-width="80px" class="demo-ruleForm" :model="state">
            <div class="contract_form_item" v-for="(item, index) in state.contactList" :key="index">
              <div class="form_box form_first">
                <el-form-item class="form_item_name" label="姓名" :prop=" 'contactList.' + index + '.contacterName' "
                  :rules="{required: true,message: '请输入姓名',trigger:'blur'}">
                  <el-input v-model="item.contacterName" placeholder="请输入姓名" size="medium"></el-input>
                </el-form-item>
                <el-form-item class="form_item_sex">
                  <el-select v-model="item.sex" style="width:100%;" size="medium">
                    <el-option label="先生" key="1" :value="1"></el-option>
                    <el-option label="女士" key="2" :value="2"></el-option>
                  </el-select>
                </el-form-item>
                <div class="icon_wrap">
                  <div class="el-icon-remove-outline icon_item" v-show="state.contactList.length>1"
                    @click="handleRemoveContract(index)"></div>
                  <div class="el-icon-circle-plus-outline icon_item" v-show="index == 0" @click="handleAddContract">
                  </div>
                </div>
              </div>
              <div class="form_box">
                <el-form-item label="职务">
                  <el-input v-model="item.position" placeholder="请输入职务"></el-input>
                </el-form-item>
                <el-form-item label="联系方式">
                  <el-input v-model="item.contactNo" placeholder="请输入联系方式"></el-input>
                </el-form-item>
              </div>
              <el-form-item label="备注">
                <el-input type="textarea" v-model="item.contacterRemark" size="medium"></el-input>
              </el-form-item>
            </div>
            <!-- <div class="contract_form_item">
              <div class="form_box form_first">
                <el-form-item class="form_item_name" label="姓名">
                  <el-input v-model="state.contractForm.name" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item class="form_item_sex">
                  <el-select v-model="state.contractForm.sex" style="width:100%;"></el-select>
                </el-form-item>
                <div class="add_icon el-icon-circle-plus-outline"></div>
              </div>
              <div class="form_box">
                <el-form-item label="职务">
                  <el-input v-model="state.contractForm.duty" placeholder="请输入职务"></el-input>
                </el-form-item>
                <el-form-item label="联系方式">
                  <el-input v-model="state.contractForm.tel" placeholder="请输入联系方式"></el-input>
                </el-form-item>
              </div>
              <el-form-item label="备注">
                <el-input type="textarea" v-model="state.contractForm.remark"></el-input>
              </el-form-item>
            </div> -->
          </el-form>
        </div>
        <div class="btn_wrap">
          <el-button type="primary" @click="handleCloseDialog">取消</el-button>
          <el-button type="primary" @click="saveContract">保存</el-button>
        </div>
      </div>
    </MyDialog>
  </section>
</template>

<script>
  import MyDialog from '@/components/myDialog'
  import {
    ElMessage
  } from 'element-plus'
  import {
    ref,
    reactive,
    onMounted
  } from 'vue'
  import {
    userRoute,
    useRouter,
    useRoute
  } from 'vue-router'
  import {
    getListByDictType
  } from '@/api'
  import {
    editBasicInfo,
    getRelevantCase,
    searchCase,
    addRelevantCase,
    removeCase,
    getRelevantProject,
    removeProject,
    searchProject,
    baseInfoDetail,
    getRelevantContract,
    addRelevantProject,
    addRelevantContract
  } from '@/api/custom'
  export default {
    name: 'CustomDetail',
    components: {
      MyDialog
    },
    setup() {
      let isShow = ref(false)
      const route = useRoute()
      const router = useRouter()
      let btnText = ref("添加关联")
      let isEdit = ref(true)
      const btnArr = ref(['详情', '统计'])
      let dialogTitle = ref("编辑案件关联")
      let showCaseDialog = ref(false)
      let showContractDialog = ref(false)
      let checked = ref(false)
      let customForm = ref(null)
      let contractForm = ref(null)
      let btnIdx = ref(0)
      let state = reactive({
        type: null, // 案件 还是 项目
        customForm: {
          customerId: null,
          customerName: '',
          customerType: 1,
          birthday: '',
          customerRemark: '',
          tagList: [],
        },
        contactList: [],
        caseIdList: [], // 添加选中关联案件的id
        checkList: [],
        customerTagArr: [],
        typeArr: [],
        rules: {
          customerType: [{
            required: true,
            message: '请选择客户类型',
            trigger: 'blur'
          }],
          customerName: [{
            required: true,
            message: '请输入姓名',
            trigger: 'blur'
          }]
        },
        keyword: ''
      })
      const goBack = () => {
        router.push({
          path: '/custom'
        })
      }
      const handleClickItem = (index) => {
        btnIdx.value = index
      }
      const handleCloseDialog = () => {
        isShow.value = false
        showCaseDialog.value = false
        showContractDialog.value = false
        // btnText.value = ''
      }
      const handleOpenDialog = (type) => {
        state.type = type
        btnText.value = '添加关联'
        if (type === 'case') { // 案件
          dialogTitle.value = '编辑案件关联'
        } else { // 项目
          dialogTitle.value = '编辑项目关联'
        }
        showCaseDialog.value = true
      }
      const handleOpenContractDialog = () => {
        if (state.contactList.length == 0) {
          let obj = {
            contacterName: '',
            sex: 1,
            position: '',
            contactNo: '',
            contacterRemark: ''
          }
          state.contactList.push(obj)
        }
        showContractDialog.value = true
      }
      const handleAddRelevance = async () => {
        isEdit.value = false
        btnText.value = '确定关联'
        if (state.type === 'case') { // 案件
          dialogTitle.value = '添加案件关联'
          if (btnText.value === '确定关联') {
            let params = {
              pageNo: 1,
              pageSize: 100000,
              customerId: state.customForm.customerId,
              keyword: state.keyword
            }
            let res = await searchCase(params)
            console.log('关联案件模糊搜索', res);
          } else {
            let params = {
              customerId: state.customForm.customerId,
              caseIdList: state.caseIdList
            }
            let res = await addRelevantCase(params)
            console.log('关联案件添加', res);
          }
        } else {
          dialogTitle.value = '添加项目关联'
          if (btnText.value === '确定关联') {
            let params = {
              pageNo: 1,
              pageSize: 100000,
              customerId: state.customForm.customerId,
              keyword: state.keyword
            }
            let res = await searchProject(params)
            console.log('关联项目模糊搜索', res);
          } else {
            let params = {
              customerId: state.customForm.customerId,
              projectIdList: state.projectIdList
            }
            let res = await addRelevantProject(params)
            console.log('关联项目新增', res);
          }
        }
      }
      const handleBack = () => {
        isEdit.value = true
        btnText.value = '添加关联'
        if (state.type === 'case') {
          dialogTitle.value = '编辑案件关联'
        } else {
          dialogTitle.value = '编辑项目关联'
        }
      }
      const handleAddContract = () => {
        let obj = {
          contacterName: '',
          sex: 1,
          position: '',
          contactNo: '',
          contacterRemark: ''
        }
        state.contactList.push(obj)
      }
      const handleRemoveContract = (index) => {
        state.contactList.splice(index, 1)
      }
      const saveBaseInfo = () => {
        customForm.value.validate(async (valid) => {
          if (valid) {
            let res = await editBasicInfo(state.customForm)
            if (res.code == 200) {
              ElMessage.success("保存成功")
              handleCloseDialog()
              getDetail()
            }
          }
        })
      }
      // 获取客户类型
      const getCustomerType = async () => {
        let res = await getListByDictType({
          id: 10005
        })
        state.typeArr = res.data
      }
      // 获取客户标签
      const getCustomTag = async () => {
        let res = await getListByDictType({
          id: 10031
        })
        state.customerTagArr = res.data
      }
      // 格式化时间
      const formatTime = (dataStr) => {
        let date = new Date(dataStr);
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        // let h = date.getHours();
        // h = h < 10 ? ('0' + h) : h;
        // //获得分
        // let mm = date.getMinutes()
        // mm = mm < 10 ? ('0' + mm) : mm;
        // //获得秒
        // let ss = date.getSeconds()
        // ss = ss < 10 ? ('0' + ss) : ss;
        // console.log(y+'-'+m+'-'+d+' '+h+':'+mm+':'+ss)
        // return y + '-' + m + '-' + d + ' ' + h + ':' + mm + ':' + ss
        return y + '-' + m + '-' + d
      }
      const handleChangeTime = (val) => {
        // let time = formatTime(val)
        // console.log(time);
        state.customForm.birthday = formatTime(val)
      }
      // 获取关联案件
      const getCaseList = async () => {
        let res = await getRelevantCase({
          customerId: state.customForm.customerId
        })
        console.log('关联案件', res);
      }
      const handleRemoveRelevant = async (item) => {
        // console.log(state.type);
        if (state.type === 'case') {
          let params = {
            caseId: item.caseId,
            customerId: state.customForm.customerId
          }
          let res = await removeCase(params)
          console.log('移除案件', res);
          if (res.code == 200) {
            let params = {
              customerId: state.customForm.customerId,
              projectId: item.projectId
            }
            let res = await removeProject(params)
            console.log('移除项目', res);
          }
        }
      }
      // 获取关联项目
      const getProjectList = async () => {
        let res = await getRelevantProject({
          customerId: state.customForm.customerId
        })
        console.log('关联项目', res);
      }
      const getDetail = async () => {
        let res = await baseInfoDetail({
          customerId: state.customForm.customerId
        })
        // console.log('基本信息详情',res);
        if (res.code == 200) {
          state.typeArr.map((item) => {
            if (item.key === res.data.customerType) {
              res.data.customerTypeName = item.value
            }
          })
          let str = ''
          res.data.tagList.map((item) => {
            state.customerTagArr.map((item2) => {
              if (item === item2.key) {
                str = str + item2.value + ' '
              }
            })
          })
          res.data.tagListName = str
          state.customForm = res.data
        }
      }
      const getContractList = async () => {
        let res = await getRelevantContract({
          customerId: state.customForm.customerId
        })
        console.log('关联联系人', res);
        if (res.code == 200) {
          state.contactList = res.data.contactList
        }
      }
      const saveContract = () => {
        contractForm.value.validate(async (valid) => {
          if (valid) {
            let params = {
              customerId: state.customForm.customerId,
              contactList: state.contactList
            }
            let res = await addRelevantContract(params)
            console.log('添加联系人', res);
            if (res.code == 200) {
              ElMessage.success("保存成功")
              getContractList()
              showContractDialog.value = false
            }
          }
        })
      }
      onMounted(() => {
        state.customForm.customerId = route.query.customerId
        getCustomerType()
        getCustomTag()
        getCaseList()
        getProjectList()
        getDetail()
        getContractList()
      })
      return {
        goBack,
        btnArr,
        btnIdx,
        handleClickItem,
        state,
        customForm,
        handleCloseDialog,
        saveBaseInfo,
        isShow,
        dialogTitle,
        showCaseDialog,
        handleOpenDialog,
        checked,
        isEdit,
        btnText,
        handleAddRelevance,
        handleBack,
        showContractDialog,
        handleAddContract,
        handleRemoveContract,
        handleChangeTime,
        handleRemoveRelevant,
        getCustomTag,
        saveContract,
        contractForm,
        handleOpenContractDialog
      }
    }
  }
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  @import '@/assets/style/comman.scss';

  .customDetail {
    width: 1200px;
    margin: 0 auto;
    background: #fff;
    border-radius: 20px;
    padding-bottom: 30px;

    .top {
      height: 90px;
      border-bottom: 1px solid #edeff2;
      display: flex;
      align-items: center;
      padding: 0 35px;

      .back {
        font-size: 14px;
        color: #606266;
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon_left {
          font-size: 20px;
          margin-right: 5px;
        }
      }

      .company_name {
        margin-left: 30px;
        font-size: 18px;
        color: #333;
      }
    }

    .btn_wrap {
      margin: 0 35px;
      display: flex;
      height: 60px;
      align-items: center;
      border-bottom: 1px solid #edeff2;

      .btn_item {
        width: 100px;
        height: 32px;
        border: 1px solid #dcdfe6;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #797979;
        cursor: pointer;

        &.active {
          border: 1px solid $main-color;
          color: $main-color;
        }

        &:last-child {
          margin-left: 20px;
        }
      }
    }

    .content_wrap {
      display: flex;
      padding: 0 35px;

      .top_wrap {
        display: flex;
        height: 70px;
        align-items: center;
        justify-content: space-between;
        padding-right: 20px;

        .title {
          font-size: 16px;
          color: #606266;
        }

        .el-icon-edit-outline {
          color: $main-color;
          font-size: 25px;
          cursor: pointer;
        }
      }

      .left {
        width: 50%;
        border-right: 1px solid #edeff2;

        .base_info_wrap {
          border-bottom: 1px solid #edeff2;

          .info_wrap {
            padding: 0 20px 30px;

            .info_item {
              height: 40px;
              display: flex;
              align-items: center;
              color: #797979;
              font-size: 14px;

              .label {
                width: 60px;
              }

              .con {
                color: #606266;
                margin-left: 20px;
              }
            }
          }
        }

        .contract_info_wrap {
          .contract_list {
            .list_item {
              display: flex;
              align-items: center;
              color: #606266;
              font-size: 14px;
              margin: 20px 0;

              .avatar {
                width: 50px;
                height: 50px;
                color: $main-color;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                }
              }

              .name {
                margin: 0 20px;
              }

              .tel {
                margin-left: 20px;
              }
            }
          }
        }
      }

      .right {

        .relevant_case_wrap,
        .relevant_project_wrap {
          border-bottom: 1px solid #edeff2;
          padding: 0 20px 30px;
          color: #606266;


        }

        .relevant_project_wrap {
          border-bottom: none;
        }
      }
    }
  }

  .case_list {
    font-size: 14px;

    .list_item {
      width: 510px;
      height: 72px;
      border-radius: 10px;
      box-shadow: 2px 2px 5px rgb(220 223 230);
      padding: 0 20px;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      margin: 10px 0;

      ::v-deep .el-checkbox__inner {
        width: 20px;
        height: 20px;
        border-radius: 50%;

        &::after {
          width: 5px;
          height: 10px;
          top: 2px;
          left: 6px;
        }
      }

      .case_info {
        flex: 1;
        margin-left: 20px;

        .case_top {
          display: flex;
          font-size: 12px;
          height: 36px;
          align-items: center;

          .case_time {}

          .case_num {
            margin-left: 25px;
          }
        }

        .case_bottom {
          font-size: 14px;
        }
      }

      // .case_status {}
    }
  }

  .custom_form {
    padding: 20px 30px;

    .form_box {
      display: flex;

      .form_item_type {
        flex: 0 0 260px;
      }

      .form_item_name {
        flex: 1;

        ::v-deep .el-form-item__content {
          margin-left: 0 !important;
        }
      }
    }

    .btn_wrap {
      display: flex;
      justify-content: flex-end;
    }
  }

  .case_dialog {
    padding: 20px 30px;
    position: relative;

    ::v-deep .el-input__inner {
      border-radius: 20px;
    }

    .back {
      font-size: 24px;
      position: absolute;
      top: -40px;
      cursor: pointer;
    }

    .case_status {
      margin-right: 30px;
    }

    .btn_wrap {
      display: flex;
      justify-content: center;
    }

  }

  .contract_dialog {
    padding: 30px 50px;

    .contract_form_item {
      padding: 10px 50px 10px 0;
      width: 530px;
      margin: 15px 0;
      border-radius: 15px;
      box-shadow: 2px 2px 5px rgb(220 223 230);

      .form_box {
        display: flex;

        .form_item_name {
          flex: 1;
        }

        .form_item_sex {
          flex: 0 0 160px;

          ::v-deep .el-form-item__content {
            margin-left: 0 !important;
          }
        }

        .icon_wrap {
          width: 70px;
          font-size: 25px;
          color: #797979;
          position: absolute;
          display: flex;
          justify-content: space-between;
          right: -85px;

          .icon_item {
            cursor: pointer;
          }
        }

        &.form_first {
          position: relative;
        }
      }
    }

    .btn_wrap {
      display: flex;
      justify-content: flex-end;
    }
  }
</style>