import myserver from './myHttp'
// 获取客户列表
export const getCustomerList = (params) => {
  return myserver.myHttp({
    url: '/customer/page',
    params
  })
}
// 新建客户
export const addCustomer = (data) => {
  return myserver.myHttp({
    url: '/customer/save',
    method: 'POST',
    data
  })
}
// 修改基本信息
export const editBasicInfo = (data) => {
  return myserver.myHttp({
    url: '/customer/update',
    method: 'POST',
    data
  })
}
// 查询关联案件
export const getRelevantCase = (params) => {
  return myserver.myHttp({
    url: '/customer/getCaseRel',
    params
  })
}
// 关联案件模糊搜索
export const searchCase = (params) => {
  return myserver.myHttp({
    url: '/customer/casePage',
    params
  })
}
// 关联案件新增
export const addRelevantCase = (data) => {
  return myserver.myHttp({
    url: '/customer/addCaseRel',
    method: 'POST',
    data
  })
}
// 移除关联案件
export const removeCase = (data) => {
  return myserver.myHttp({
    url: '/customer/removeCaseRel',
    method: 'POST',
    data
  })
}
// 查询关联项目
export const getRelevantProject = (params) => {
  return myserver.myHttp({
    url: '/customer/getProjectRel',
    params
  })
}
// 关联项目模糊搜索
export const searchProject = (params) => {
  return myserver.myHttp({
    url: '/customer/projectPage',
    params
  })
}
// 移除关联项目
export const removeProject = (data) => {
  return myserver.myHttp({
    url: '/customer/removeProjectRel',
    method: 'POST',
    data
  })
}
// 关联项目新增
export const addRelevantProject = (data) => {
  return myserver.myHttp({
    url: '/customer/projectRel',
    method: 'POST',
    data
  })
}
// 基本信息详情
export const baseInfoDetail = (params) => {
  return myserver.myHttp({
    url: '/customer/detail',
    params
  })
}
// 查询关联联系人
export const getRelevantContract = (params) => {
  return myserver.myHttp({
    url: '/customer/getContacterRel',
    params
  })
}
// 新增联系人
export const addRelevantContract = (data) => {
  return myserver.myHttp({
    url: '/customer/contacterRel',
    method: 'POST',
    data
  })
}